<template>
  <div class="scratch_ide">
    <canvas id="scratch"></canvas>
  </div>
</template>
  
<script>
const ScratchRender = require("scratch-render/dist/web/scratch-render");
const VirtualMachine = require("scratch-vm/dist/web/scratch-vm");
const ScratchStorage = require("scratch-storage/dist/web/scratch-storage");
const ScratchSVGRenderer = require("scratch-svg-renderer/dist/web/scratch-svg-renderer");
const AudioEngine = require("scratch-audio/src/AudioEngine.js");

export default {
  name: "scratch_ide",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  components: {},
  mounted() {
    let that = this;

    const url =
      "https://7374-steamleader-155c4d-1301115590.tcb.qcloud.la/Scratch/13007900808/0.sb3?sign=0bccc24d2abdfd122005814869e64304&t=1611991402";
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        window.devicePixelRatio = 1;

        var canvas = document.getElementById("scratch");
        var audioEngine = new AudioEngine();
        var render = new ScratchRender(canvas);
        var vm = new VirtualMachine();
        var storage = new ScratchStorage();
        var mockMouse = (data) =>
          vm.runtime.postIOData("mouse", {
            canvasWidth: canvas.width,
            canvasHeight: canvas.height,
            ...data,
          });
        vm.attachAudioEngine(audioEngine);
        vm.attachStorage(storage);
        vm.attachRenderer(render);
        vm.attachV2SVGAdapter(new ScratchSVGRenderer.SVGRenderer());
        vm.attachV2BitmapAdapter(new ScratchSVGRenderer.BitmapAdapter());
        this.vm = vm;
        this.bindHandleKey();
        var reader = new FileReader();
        //byte为blob对象
        reader.readAsArrayBuffer(blob);
        reader.onload = () => {
          vm.start();
          vm.loadProject(reader.result).then(() => {
            that.loadFileTrue = true;
            const div = document.createElement("div");
            div.id = "loaded";
            document.body.appendChild(div);
          });
        };
      });
  },
  methods: {
    // 绑定鼠标事件
    bindHandleKey() {
      document.addEventListener("keydown", this.handleKeyDown);
      document.addEventListener("keyup", this.handleKeyUp);
    },
    handleKeyDown(e) {
      // Don't capture keys intended for Blockly inputs.
      if (e.target !== document && e.target !== document.body) return;
      const key = !e.key || e.key === "Dead" ? e.keyCode : e.key;
      this.vm.postIOData("keyboard", {
        key: key,
        isDown: true,
      });
      // Prevent space/arrow key from scrolling the page.
      if (
        e.keyCode === 32 || // 32=space
        (e.keyCode >= 37 && e.keyCode <= 40)
      ) {
        // 37, 38, 39, 40 are arrows
        e.preventDefault();
      }
    },
    handleKeyUp(e) {
      // Always capture up events,
      // even those that have switched to other targets.
      const key = !e.key || e.key === "Dead" ? e.keyCode : e.key;
      this.vm.postIOData("keyboard", {
        key: key,
        isDown: false,
      });
      // E.g., prevent scroll.
      if (e.target !== document && e.target !== document.body) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
</style>